<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img src="~assets/manager.jpg" alt="" />
        <span>Rivvoo-后台管理系统</span>
      </div>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '61px' : '200px'">
        <div class="toggle-button" @click="toggleCollpase">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#e64a19"
          text-color="#fff"
          active-text-color="#409EFF"
          :unique-opened="true"
          :collapse="isCollapse"
          :collapse-transition="false"
          :router="true"
          :default-active="activePath"
        >
          <!-- 一级菜单 -->
          <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iconsObj[item.id]"></i>
              <!-- 文本 -->
              <span>{{ item.authName }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item :index="'/' + sunItem.path" v-for="sunItem in item.children" :key="sunItem.id" @click="saveActivePath('/' + sunItem.path)">
              <template slot="title">
                <!-- 二级菜单的模板区域 -->
                <i class="el-icon-menu"></i>
                <!-- 图标 -->
                <span>{{ sunItem.authName }}</span>
                <!-- 文本 -->
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主题 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { time } from 'echarts'
export default {
  name: 'Home',
  data() {
    return {
      // 左侧菜单数据
      menuList: [],
      iconsObj: {
        // 一级菜单的icon图标
        147: 'el-icon-magic-stick',
        125: 'iconfont icon-users',
        103: 'iconfont icon-tijikongjian',
        101: 'iconfont icon-shangpin',
        102: 'iconfont icon-danju',
        104: 'iconfont icon-tijikongjian',
        105: 'iconfont icon-tijikongjian',
        106: 'iconfont icon-tijikongjian',
        107: 'iconfont icon-tijikongjian',
        108: 'iconfont icon-danju',
        113: 'iconfont icon-tijikongjian',
        114: 'iconfont icon-tijikongjian',
        115: 'iconfont icon-tijikongjian',
        116: 'iconfont icon-tijikongjian',
        145: 'iconfont icon-baobiao',
        146: 'iconfont icon-baobiao',
        147: 'iconfont icon-baobiao',
        148: 'iconfont icon-baobiao',
        149: 'iconfont icon-baobiao',
        150: 'iconfont icon-baobiao',
        151: 'iconfont icon-baobiao',
        152: 'iconfont icon-baobiao',
        153: 'iconfont icon-baobiao',
        154: 'iconfont icon-baobiao',
        155: 'iconfont icon-baobiao',
        156: 'iconfont icon-baobiao',
        157: 'iconfont icon-baobiao',
        158: 'iconfont icon-danju',
        159: 'iconfont icon-danju',
        161: 'iconfont icon-danju',
        162: 'iconfont icon-danju',
        163: 'iconfont icon-danju',
        164: 'iconfont icon-danju',
        200: 'iconfont icon-user',
        201: 'iconfont icon-danju',
        202: 'iconfont icon-danju',
        203: 'iconfont icon-danju',
        204: 'iconfont icon-danju',
      },
      isCollapse: false, // 是否折叠属性
      activePath: '',
      welcome: {
        authName: '欢迎光临',
        id: 147,
        order: 1,
        path: '/welcome',
        children: [
          {
            authName: 'Welcome',
            id: 124,
            order: 1,
            path: 'welcome',
            children: []
          }
        ]
      },
      all_menu:{
        welcome: {
          authName: '欢迎光临',
          id: 147,
          order: 1,
          path: '/welcome',
          children: [
            {
              authName: 'Welcome',
              id: 124,
              order: 1,
              path: 'welcome',
              children: []
            }
          ]
        },
        orders:{ 
          authName: '订单管理',
          children: [{ 
            id: 112, 
            authName: '订单列表', 
            path: 'orders', 
            children: [], 
            order: null 
          }],
          id: 102,
          order: 5,
          path: 'orders'
        },
        products:{ 
          authName: '抢单管理',
          children: [{ 
            id: 112, 
            authName: '艺术品列表', 
            path: 'artworks', 
            children: [], 
            order: null 
          },{ 
            id: 113, 
            authName: '规则列表', 
            path: 'rules', 
            children: [], 
            order: null 
          },{ 
            id: 114, 
            authName: '抢单列表', 
            path: 'bills', 
            children: [], 
            order: null 
          },{ 
            id: 153, 
            authName: '运营者列表', 
            path: 'operators', 
            children: [], 
            order: null 
          },{ 
            id: 154, 
            authName: '抢单统计', 
            path: 'grabstatis', 
            children: [], 
            order: null 
          }],
          id: 146,
          order: 5,
          path: 'products'
        },
        transfer:{ 
          authName: '空投管理',
          children: [
            { id: 157, authName: '空投列表', path: 'transfer', children: [], order: null 
          }],
          id: 107,
          order: 2,
          path: 'transfer'
        },
        bindbox:{ 
          authName: '盲盒管理',
          children: [
            { id: 158, authName: '盲盒列表', path: 'bindbox', children: [], order: null 
          }],
          id: 108,
          order: 2,
          path: 'bindbox'
        },
        appeal:{ 
          authName: '投诉管理',
          children: [
            { id: 115, authName: '投诉列表', path: 'appeals', children: [], order: null 
          }],
          id: 104,
          order: 2,
          path: 'appeal'
        },
        cert: { 
          authName: '实名管理',
          children: [
            { id: 116, authName: '实名列表', path: 'certs', children: [], order: null 
          }],
          id: 105,
          order: 2,
          path: 'cert'
        },
        users: {
          authName: '用户管理',
          children: [
            {authName: '用户列表',children: [],id: 110,order: null,path: 'users'},
            {authName: '艺术家列表',children: [],id: 111,order: null,path: 'artists'}
            ],
          id: 125,
          order: 1,
          path: 'users'
        },
        rights:  { 
          authName: '权限管理',
          children: [
            { id: 111, authName: '角色列表', path: 'roles', children: [], order: null 
          }],
          id: 103,
          order: 2,
          path: 'rights'
        },
        goods:{
          authName: "艺术品管理",
          children: [
            {id: 104, authName: "艺术品列表", path: "goods", children: [], order: 1},
            {id: 115, authName: "公链列表", path: "chains", children: [], order: 2},
            // {id: 115, authName: "分类参数", path: "params", children: [], order: 2},
            {id: 121, authName: "艺术品分类", path: "categories", children: [], order: 3},
            {id: 160, authName: "转赠记录", path: "artturn", children: [], order: 4},
            {id: 161, authName: "合成作品", path: "synthetic", children: [], order: 1},
            {id: 163, authName: "系列", path: "series", children: [], order: 1},
            {id: 164, authName: "提取记录", path: "extract", children: [], order: 1},
          ],
          id: 106,
          order: 3,
          path: "goods"
        },
        roteMaps:{
          authName: "轮播管理",
          children: [
            {id: 147, authName: "轮播列表", path: "rotemaps", children: [], order: 1},
          ],
          
          id: 101,
          order: 3,
          path: "rotemaps"
        },
        help:{
          authName: "帮助中心",
          children: [
            {id: 149, authName: "常见问题", path: "faq", children: [], order: 1},
            {id: 150, authName: "关于我们", path: "aboutus", children: [], order: 1},
            {id: 151, authName: "协议条款", path: "terms", children: [], order: 1},
            {id: 152, authName: "关于抢单", path: "aboutgrab", children: [], order: 1},
            {id: 155, authName: "隐私协议", path: "privacy", children: [], order: 1},
            {id: 156, authName: "用户政策", path: "policy", children: [], order: 1},
            {id: 159, authName: "通知管理", path: "notice", children: [], order: 1},
            {id: 160, authName: "排行", path: "top", children: [], order: 1},
          ],
          id: 148,
          order: 3,
          path: "faq"
        },
        wish:{
          authName: "义链宇宙",
          children: [
            {id: 201, authName: "义链规则", path: "public_welfare", children: [], order: 1},
            {id: 202, authName: "愿望批次", path: "wish-batch", children: [], order: 1},
            {id: 203, authName: "愿望列表", path: "wish", children: [], order: 1},
            {id: 204, authName: "投票明细", path: "wish-praise", children: [], order: 1},
          ],
          id: 200,
          order: 3,
          path: "wish"
        }
      },
      allow_role:['admin','operate']
    }
  },
  created() {
    let role = this.$cookie.get('role')
    let key =  this.allow_role.indexOf(role)
    if(key < 0){
      this.$message.error('该用户没有权限')
      this.$router.push('/login')
    }
    this.getMenuList()
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    logout() {
      // window.sessionStorage.clear()
      this.$cookie.set('role',"")
      this.$router.push('/login')
    },
    // 获取所有的菜单
    async getMenuList() {
      let role = this.$cookie.get('role')
      if(role == 'operate'){
        let  power = ["orders","products","appeal","transfer","bindbox"]
        power.forEach(element => {
          this.menuList.push(this.all_menu[element])
        });
      }
      if(role == 'admin'){//,"cert"
        let  power = ["welcome","users","rights","goods","roteMaps","orders","products","appeal","transfer","bindbox","help","wish"]
        power.forEach(element => {
          this.menuList.push(this.all_menu[element])
        });
      }
      
    },
    // 点击按钮,切换菜单的折叠和展开
    toggleCollpase() {
      this.isCollapse = !this.isCollapse
    },
    saveActivePath(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
      // console.log(this.$route.path)
    }
  }
}
</script>

<style lang="less" scoped>
.hwelcome {
  color: #fff;
  font-size: 15px;
  height: 25px;
  text-align: center;
  background: green;
  span {
    margin-left: 10px;
  }
}
.home-container {
  height: 100%;
}
.el-header {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  background-color: #e64a19;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    span {
      margin-left: 15px;
    }
  }
}
.el-aside {
  background-color: #e64a19;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #eaedf1;
}
.iconfont {
  padding-right: 10px;
}
.toggle-button {
  background: #e64a19;
  font-size: 10px;
  text-align: center;
  line-height: 24px;
  color: #fff;
  letter-spacing: 0.2em;
  cursor: pointer;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  box-sizing: border-box;
}
.el-submenu__title i, .el-submenu__icon-arrow {
  color: #ffffff;
}
.el-submenu__icon-arrow {
    color: #ffffff!important;
}
.el-menu-item i {
    color: #ffffff;
}
.el-menu-item.is-active i {
    color: inherit;
}
.el-submenu__title {
  background-color: #e64a19!important;
}
</style>
