<template>
  <div class="welcome">
    <div class="bgImg">
      <div class="imgItem">
        <img :src="img"/>
      </div>
      <div>
        <h3>Welcome</h3>
        <h3>欢迎登录Rivvoo后台管理系统!</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  data () {
    return {
      img: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fp9.itc.cn%2Fq_70%2Fimages03%2F20210218%2F329ffb7e3a174d2fa69bd752f452ffdd.gif&refer=http%3A%2F%2Fp9.itc.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642245913&t=707833e96604bfd4d9824bf1de4ac0fa'
    }
  }
}
</script>

<style lang="less" scoped>
.welcome {
  font-size: 20px;
  text-align: center;
  color: rgb(230, 74, 25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .bgImg {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    .imgItem {
      width: 20%;
      img {
        width: 100%;
      }
    }
  }
  h3 {
    margin: 20px auto;
    font-size: 30px;
    letter-spacing: 3px;
  }
}
</style>
